<template lang="pug">
.company-finder
  v-combobox(
    v-model="company"
    label="Название организации или ИНН"
    :items="res"
    item-text="name"
    clearable
    no-filter
    return-object
    rounded
    solo
    append-icon=""
    :search-input.sync="query"
    @change="$emit('select', $event)"
    :loading="isLoading"
  )
    template(#prepend-inner)
      ui-icon(name="search" size=24)
    template(v-slot:item="data")
      v-list-item-content
        v-list-item-title(v-html="data.item.name")
        v-list-item-subtitle(v-html="data.item.inn")
</template>

<script>
  import { service as API } from '@/services/auth';
  import { UiIcon } from '@/uikit';
  import debounce from 'underscore/modules/debounce.js';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('CompanyFinder', 'green');

  export default {
    name: 'company-finder',
    components: { UiIcon },

    data() {
      return {
        isLoading: false,
        query: null,
        company: null,
        res: [],
      };
    },

    watch: {
      query(val) {
        if (val) {
          this.debouncedFetchLocations();
        }
      },
    },

    created() {
      this.debouncedFetchLocations = debounce(this.findCompany, 400);
    },

    methods: {
      async findCompany() {
        try {
          this.isLoading = true;
          const { data } = await API.findCompanyByNameInn(this.query);
          this.res = data;
        } catch (error) {
          logger.log(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .company-finder {
    .v-text-field--rounded > .v-input__control > .v-input__slot {
      padding: 0 0 0 12px;
      border: 1px solid c('black40', 'light');
      border-radius: 24px;

      &:hover {
        border: 1px solid c('black80', 'light');
      }
    }
    .icon {
      color: c('black60', 'light');
    }
    .v-input__append-inner {
      margin-right: 8px;

      .v-icon__svg {
        color: c('black80', 'light');
      }
    }
    .v-input--is-focused {
      .icon {
        color: c('black80', 'light');
      }
      .v-input__slot {
        border: 2px solid c('primary40', 'light');
      }
    }
  }
</style>
