<template lang="pug">
app-layout
  v-app.layout-full
    app-nav-drawer(v-model="navDrawer")
    app-header(no-buttons @nav:click="navDrawer = true")

    v-main
      .page-sign-up-legal.pa-6.pb-8
        router-link.link--no-underline.d-flex.align-center.c-primary.px-0.mb-4(:to="$path.signUp()")
          ui-icon(name="arrow-left" left)
          .t-caption-middle Назад
        .t-h1-mobile.mb-1 Регистрация
        .t-subtitle.c-gray80.mb-8 Юридическое лицо – Бизнес-аккаунт

        template(v-if="step === 0")
          ui-switch.mb-9(
            :value="foreignCompany"
            label="Иностранная компания"
            @change="foreignCompany = !foreignCompany"
          )
          template(v-if="foreignCompany")
            v-text-field.mb-7(
              v-model="company.name"
              type="text"
              label="Наименование"
              hide-details="auto"
              key="company_name"
              :error="hasError('company_name')"
              :error-messages="errorMsg('company_name')"
            )
              template(v-if="company.name" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="company.inn"
              type="text"
              label="ИНН"
              hide-details="auto"
              key="company_inn"
              v-maska="maska.fields.company_inn.maska"
              :error="hasError('company_inn')"
              :error-messages="errorMsg('company_inn')"
            )
              template(v-if="company.inn && company.inn.length == 10" #append)
                ui-icon.c-primary(name="check2")
          template(v-else)
            //- пока что скрыто
            //- v-text-field.mb-7(
            //- v-model="company.search"
            //- type="text"
            //- label="Поиск"
            //- hide-details="auto"
            //- )
            company-finder(v-model="search" @select="selectedCompany")
            v-text-field.mb-7(
              v-model="company.name"
              type="text"
              label="Наименование"
              hide-details="auto"
              key="company_name"
              :error="hasError('company_name')"
              :error-messages="errorMsg('company_name')"
            )
              template(v-if="company.name" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="company.inn"
              type="text"
              label="ИНН"
              hide-details="auto"
              key="company_inn"
              v-maska="maska.fields.company_inn.maska"
              :error="hasError('company_inn')"
              :error-messages="errorMsg('company_inn')"
            )
              template(v-if="company.inn && company.inn.length == 10" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="company.address"
              type="text"
              label="Юридический адрес"
              hide-details="auto"
              key="company_address"
              :error="hasError('company_legal_address')"
              :error-messages="errorMsg('company_legal_address')"
            )
              template(v-if="company.address" #append)
                ui-icon.c-primary(name="check2")
          ui-loader.my-6(preloader :loading="loaders.company")
          ui-btn.mt-2(
            large
            primary
            width="100%"
            @click="checkCompany"
            :disabled="!checkStep"
          ) Далее
        template(v-if="step === 1")
          .company-frame
            .t-normal-bold {{ company.name }}
            .t-normal ИНН {{ company.inn }}
            .t-normal {{ company.address }}

          .t-caption-normal.mb-6 Выберите способ авторизации
          v-radio-group.mb-5.mt-0.pt-0(v-model="regMethod")
            v-radio.mb-4(hide-details key="email" value="email")
              template(v-slot:label)
                .t-caption-normal.c-gray80 По электронной почте и паролю
            v-radio(hide-details key="phone" value="phone")
              template(v-slot:label)
                .t-caption-normal.c-gray80 По телефону через СМС

          v-text-field.mb-7(
            v-model="user.name"
            type="text"
            label="ФИО"
            hide-details="auto"
            key="name"
            :error="hasError('name')"
            :error-messages="errorMsg('name')"
          )
            template(v-if="user.name && !errors.name" #append)
              ui-icon.c-primary(name="check2")
          v-text-field.mb-7(
            v-model="user.position"
            type="text"
            label="Должность"
            hide-details="auto"
            key="position"
            :error="hasError('position')"
            :error-messages="errorMsg('position')"
          )
            template(v-if="user.position && !errors.position" #append)
              ui-icon.c-primary(name="check2")
          template(v-if="regMethod === 'email'")
            v-text-field.mb-7(
              v-model="user.phone"
              type="tel"
              label="Телефон"
              :placeholder="maska.PHONE_PLACEHOLDER"
              hide-details="auto"
              key="phone"
              v-maska="maska.PHONE_VMASK"
              :error="hasError('phone')"
              :error-messages="errorMsg('phone')"
            )
              template(v-if="user.phone && user.phone.length == 18 && !errors.phone" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="user.email"
              type="email"
              label="Электронная почта"
              hide-details="auto"
              key="email"
              :error="hasError('email')"
              :error-messages="errorMsg('email')"
            )
              template(v-if="user.email && emailChecked && !errors.email" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="user.password"
              type="password"
              label="Придумайте пароль"
              hide-details="auto"
              key="password"
              :error="hasError('password')"
              :error-messages="errorMsg('password')"
            )
              template(v-if="user.password && passwordChecked && !errors.password" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="user.confirmation_password"
              type="password"
              label="Повторите пароль"
              hide-details="auto"
              key="confirmation_password"
              :error="hasError('password_confirmation')"
              :error-messages="errorMsg('password_confirmation')"
            )
              template(
                v-if="user.confirmation_password && passwordChecked && !errors.password_confirmation"
                #append
              )
                ui-icon.c-primary(name="check2")
          template(v-if="regMethod === 'phone'")
            v-text-field.mb-7(
              v-model="user.email"
              type="email"
              label="Электронная почта"
              hide-details="auto"
              key="email"
              :error="hasError('email')"
              :error-messages="errorMsg('email')"
            )
              template(v-if="user.email && emailChecked && !errors.email" #append)
                ui-icon.c-primary(name="check2")
            v-text-field.mb-7(
              v-model="user.phone"
              type="tel"
              label="Номер мобильного телефона"
              :placeholder="maska.PHONE_PLACEHOLDER"
              hide-details="auto"
              key="phone"
              v-maska="maska.PHONE_VMASK"
              :error="hasError('phone')"
              :error-messages="errorMsg('phone')"
              :disabled="waitSms"
            )
              template(v-if="user.phone && user.phone.length == 18 && !errors.phone" #append)
                ui-icon.c-primary(name="check2")
            template
              ui-loader.my-6(preloader :loading="loaders.phone")
              ui-btn.my-4(
                v-if="user.phone && !loaders.phone && user.phone.length == 18 && regMethod === 'phone' && !waitSms"
                large
                secondary
                width="100%"
                @click="confirmPhone"
              ) Подтвердить номер
            template(v-if="waitSms")
              .t-caption-middle.c-primary.mb-4(@click="setNumber") Изменить номер телефона
              template(v-if="!checkedSms")
                .t-caption-middle.mb-4 Код отправлен на указанный номер телефона
                v-text-field.mt-4(
                  v-model="sms_code"
                  label="Код из смс"
                  autofocus
                  hide-details="auto"
                  key="sms_code"
                  v-maska="maska.SMS_CODE_VMASK"
                  :error="hasError('code')"
                  :error-messages="errorMsg('code')"
                )
                  template(v-if="checkedSms" #append)
                    ui-icon.c-primary(name="check2")
                  template(v-else #append)
                    ui-countdown-timer(
                      ref="countdownTimer"
                      v-model="codeTimeLeft"
                      :duration="resendCodeTimerDuration"
                    )
                      .t-small.c-text {{ codeTimeLeft | duration }}

                template(v-if="codeTimeLeft == 0")
                  .t-caption-middle.my-4 Не пришла СМС с кодом?&nbsp;
                  .t-caption-middle.c-primary.mb-4(@click="resendCode") Отправить повторно

                ui-loader.my-6(preloader :loading="loaders.sms")
                ui-btn.my-4(
                  large
                  secondary
                  width="100%"
                  @click="confirmSmsCode"
                ) Подтвердить

          .d-flex.align-start.my-6
            v-checkbox.ma-0.pa-0(
              v-model="user.agreement"
              hide-details="auto"
              key="agreement"
              :error="hasError('agreement')"
              :error-messages="errorMsg('agreement')"
            )
            .t-caption-normal(:class="user.agreement ? 'c-black' : 'c-text'")
              | Я принимаю условия&nbsp;
              a.link.link-icon-open-window.link-icon--small.c-primary(
                href=`${process.env.VUE_APP_API_HOST}/documents/agreement.pdf?v1`
                target="_blank"
              ) пользовательского соглашения
              | &nbsp;и&nbsp;согласен на обработку моих&nbsp;
              a.link.link-icon-open-window.link-icon--small.c-primary(
                href=`${process.env.VUE_APP_API_HOST}/documents/personal_data.pdf?v1`
                target="_blank"
              ) персональных данных
          ui-loader.my-6(preloader :loading="loaders.registration")
          ui-btn.mt-2(
            v-if="!loaders.registration"
            large
            primary
            width="100%"
            @click="registration"
            :disabled="!checkStep"
          ) Зарегистрироваться

    welcome-pro-dialog(ref="welcomeProDialog" :user="currentUser")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import AppNavDrawer from '@/components/app/AppNavDrawer';
  import AppHeader from '@/components/app/AppHeader';

  import { UiBtn, UiIcon, UiLoader, UiCountdownTimer } from '@/uikit';
  import UiSwitch from '@/uikit/components/UiSwitch';

  import { maska } from 'maska';
  import Constants from '@/uikit/util/constants';
  import WelcomeProDialog from '@/components/auth/WelcomeProDialog.vue';
  import CompanyFinder from '@/components/auth/CompanyFinder.vue';

  import { mapGetters, mapActions } from 'vuex';
  import { service as API } from '@/services/auth';
  import { handleError } from '@/libs/handle-error';
  import { formErrors } from '@/uikit/mixins/formErrors';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageSignUpLegal', 'blue');

  const SMS_COUNTDOWN_DURATION = 60; // сек

  export default {
    name: 'page-sign-up-legal',
    components: {
      AppLayout,
      AppNavDrawer,
      AppHeader,
      UiBtn,
      UiIcon,
      UiSwitch,
      UiLoader,
      UiCountdownTimer,
      WelcomeProDialog,
      CompanyFinder,
    },
    directives: {
      maska,
    },
    mixins: [formErrors],

    data: () => ({
      navDrawer: false,
      loading: false,
      step: 0,
      foreignCompany: false,
      maska: Constants,
      search: undefined,
      company: {
        name: undefined,
        inn: undefined,
        address: undefined,
      },
      regMethod: 'email',
      user: {
        name: undefined,
        position: undefined,
        email: undefined,
        phone: undefined,
        password: undefined,
        confirmation_password: undefined,
        agreement: false,
      },
      waitSms: false,
      sms_code: undefined,
      checkedSms: false,
      codeTimeLeft: undefined,
      resendCodeTimerDuration: SMS_COUNTDOWN_DURATION,
      loaders: {
        phone: false,
        sms: false,
        registration: false,
        company: false,
      },
      errors: {},
      redirectToDataRoom: false,
    }),

    computed: {
      checkStep() {
        if (this.step === 0) {
          return this.foreignCompany
            ? !!this.company.name && this.company.inn?.length == 10
            : !!this.company.name && this.company.inn?.length == 10 && !!this.company.address;
        }
        return this.checkSecondStep();
      },

      emailChecked() {
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.user.email);
      },

      passwordChecked() {
        return this.user.password === this.user.confirmation_password;
      },

      verifyType() {
        return this.regMethod === 'phone' ? 'sms' : 'password';
      },

      ...mapGetters(['currentUser']),
    },

    mounted() {
      if (sessionStorage.getItem('phoneToRegister')) {
        let storedPhone = sessionStorage.getItem('phoneToRegister');

        // Проверка наличия 8 в начале и замена на +7
        if (storedPhone.startsWith('8') && storedPhone.length > 2) {
          this.user.phone = '+7' + storedPhone.slice(1);
        } else if (storedPhone.startsWith('9') && storedPhone.length > 2) {
          this.user.phone = '+7' + storedPhone;
        } else {
          this.user.phone = storedPhone;
        }
      }

      if (sessionStorage.getItem('emailToRegister')) {
        this.user.email = sessionStorage.getItem('emailToRegister');
      }
    },

    methods: {
      async checkCompany() {
        try {
          this.loaders.company = true;
          const company_info = {
            company_name: this.company.name,
            company_inn: this.company.inn,
            company_legal_address: this.company.address,
            foreign_company: this.foreignCompany,
          };

          const data = await API.checkCompany(company_info);

          if (this.foreignCompany) {
            this.company.address = undefined;
          }

          if (data) {
            this.step = 1;
          }
        } catch (error) {
          handleError(error, logger);

          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(
              error.data,
              'company_name',
              'company_inn',
              'company_legal_address'
            );
          }
        } finally {
          this.loaders.company = false;
        }
      },

      async registration() {
        try {
          this.clearErrors();
          this.loaders.registration = true;

          if (localStorage.getItem('redirectToDataRoom')) {
            this.redirectToDataRoom = true;
          }

          const user_info = {
            name: this.user.name,
            phone: this.user.phone,
            email: this.user.email,
            password: this.user.password,
            password_confirmation: this.user.confirmation_password,
            sms_code: this.sms_code,
            user_type: 'legal',
            verify_type: this.verifyType,
            agreement: this.agreement,
            company_name: this.company.name,
            company_inn: this.company.inn,
            company_legal_address: this.company.address,
            employee_position: this.user.position,
            foreign_company: this.foreignCompany,
            redirect_to_data_room: this.redirectToDataRoom,
          };

          // UTM метки
          if (localStorage.getItem('utms')) {
            user_info.utms = JSON.parse(localStorage.getItem('utms'));
          }

          logger.log('registration user_info', user_info);

          const { token, refreshToken, user, redirect_uri } = await API.registration(user_info);
          this.signInWithToken({ token, refreshToken, user, redirect_uri });

          this.$notify.info('Вы успешно зарегистрировались и авторизованы');
          if (redirect_uri) {
            window.location.href = redirect_uri
          }
          // идем на про?
          if (await this.$refs.welcomeProDialog.open()) {
            this.$router.push(this.$path.createPRO());
          } else {
            this.$router.push(this.$path.home());
          }
        } catch (error) {
          handleError(error, logger);
          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(
              error.data,
              'name',
              'phone',
              'email',
              'password',
              'password_confirmation',
              'sms_code',
              'employee_position',
              'agreement'
            );
          }
        } finally {
          this.loaders.registration = false;
          localStorage.removeItem('redirectToDataRoom');
          this.redirectToDataRoom = false;
        }
      },

      checkSecondStep() {
        return (
          this.user.name &&
          this.user.position &&
          this.emailChecked &&
          this.passwordChecked &&
          this.user.phone?.length == 18 &&
          this.user.agreement &&
          this.checkMethod()
        );
      },

      checkMethod() {
        if (this.regMethod === 'email') {
          return !!this.user.password && !!this.user.confirmation_password;
        }
        return !!this.checkedSms;
      },

      async confirmPhone() {
        try {
          this.clearErrors();
          this.loaders.phone = true;
          const data = await API.requestCode(this.user.phone);
          logger.log(data);
          this.waitSms = true;
        } catch (error) {
          handleError(error, logger);

          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(error.data, 'phone');
          }
        } finally {
          this.loaders.phone = false;
        }
      },

      setNumber() {
        this.waitSms = false;
        this.checkedSms = false;
        this.sms_code = undefined;
        this.clearErrors();
      },

      async confirmSmsCode() {
        try {
          this.clearErrors();
          this.checkedSms = false;
          this.loaders.sms = true;
          const data = await API.verifyCode(this.user.phone, this.sms_code);
          logger.log(data);
          this.checkedSms = data.ok;
        } catch (error) {
          handleError(error, logger);

          if (error.data && error.data.data) {
            this.$notify.error(error.data.message);
            this.extractResponseErrors(error.data, 'code', 'phone');
          }
        } finally {
          this.loaders.sms = false;
        }
      },

      resendCode() {
        this.sms_code = undefined;
        this.$refs.countdownTimer.resetTimer();
        this.confirmPhone();
      },

      selectedCompany(obj) {
        this.company.name = obj.name;
        this.company.inn = obj.inn;
        this.company.address = obj.address;
      },

      ...mapActions('user', ['signInWithToken']),
    },
  };
</script>

<style lang="scss">
  .page-sign-up-legal {
    .company-frame {
      background: c('gray5', 'light');
      padding: 16px 24px;
      margin: 0 -24px;
      color: c('gray80', 'light');
      margin-bottom: 24px;
    }
    .v-radio.v-item--active label > div {
      color: c('gray100', 'light') !important;
    }

    .back-link {
      position: relative;
      color: c('primary60', 'light');
      text-decoration: none;
      padding-left: 28px;
      display: block;
      font-size: 14px;

      &::after {
        @include svg-bg-mask-recolored('chevron', c('primary60', 'light'), light);
        transform: rotate(180deg);
        position: absolute;
        content: '';
        top: -2px;
        left: 0px;
        width: 24px;
        height: 24px;
      }
    }
    .ui-loader {
      display: flex;
    }
    .v-text-field__slot label.v-label--active {
      color: #909497 !important;
    }
    .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
      background: none;
    }
  }
</style>
