<template lang="pug">
.ui-switch
  label.ui-switch__wrapper
    input(v-model="checked" type="checkbox" :value="value")
    span.slider.round
  .ui-switch__label {{ label }}
</template>

<script>
  export default {
    name: 'ui-switch',
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      checked: {
        get() {
          return this.value;
        },
        set(checked) {
          this.$emit('change', checked);
        },
      },
    },
  };
</script>

<style lang="scss">
  .ui-switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__label {
      margin-left: 8px;
    }

    &__wrapper {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 20px;

      input {
        width: 0;
        height: 0;
        opacity: 0;

        &:checked + .slider::before {
          transform: translate(20px, 50%);
        }
      }
    }

    .slider {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include transition;
      background-color: c('black10', 'light');
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 2px;
        width: 16px;
        height: 16px;
        @include transition;
        background-color: c('white', 'light');
        transform: translateY(50%);
      }
    }

    input:checked + .slider {
      background-color: c('primary', 'light');
    }

    input:focus + .slider {
      box-shadow: 0 0 1px c('primary', 'light');
    }

    .slider.round {
      border-radius: 16px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
</style>
